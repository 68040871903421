//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 50%) !default; 	// #555
$gray-light:             lighten($gray-base, 70%) !default;   // #999
$gray-lighter:           lighten($gray-base, 96%) !default; 	// #eee
$gray-500:               #ededed;
$gray-400:               #ddd;

$brand-primary:         #77664d !default;
$brand-second:          #bca27a !default;
$brand-tierce:          #eac600 !default;
$brand-success:         #afc803 !default;
$brand-info:            #41aae1 !default;
$brand-warning:         #f26522 !default;
$brand-danger:          #cf2d13 !default;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               rgba(#fff, .9) !default;
//** Global text color on `<body>`.
$text-color:            darken($brand-primary, 25%) !default;
//** Global titles color.
$headings-color:        $gray-darker !default;

//** Global textual link color.
$link-color:            $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      lighten($link-color, 20%) !default;
//** Link hover decoration.
$link-hover-decoration: none !default;
